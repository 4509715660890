.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.react-datepicker-popper {
  z-index: 100 !important 
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #8cd7de;
}
.triangle {
  background-image: linear-gradient(to top right, rgb(0, 0, 0) 50%, #ED1C24 50%);

}

.swal2-icon.swal2-info {
  border-color: #179eaa !important;
  color: #179eaa !important;
}
.bg-swal {
  background: rgba(255, 255, 255, 0.406) !important;
  backdrop-filter: blur(2px) !important;
}
.custom-loader {
  animation: none !important;
  border-width: 0 !important;
}

.triangleColor {
  background-image: linear-gradient(to top right, rgb(0, 0, 0) 50%, #179eaa 50%);

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
